var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-layout',{attrs:{"wrap":""}},[_c('v-flex',{staticClass:"dtouchMobilePreview",staticStyle:{"padding":"5px"},attrs:{"xs2":""}},[_c('v-btn',{staticStyle:{"margin-bottom":"10px"},attrs:{"color":"green","block":"","small":"","dark":""},on:{"click":function($event){return _vm.handleRefresh('dtouch')}}},[_vm._v(" refresh ")]),_c('preview',{attrs:{"url":_vm.prepareDtouch(_vm.LMUDtouch),"locale":_vm.locale,"size":{
        w: 375,
        h: 799,
      },"className":"dtouchMobilePreview","name":"if-dtouch"}})],1),_c('v-flex',{staticClass:"dtouchTotemPreview",staticStyle:{"padding":"5px"},attrs:{"xs3":""}},[_c('v-btn',{staticStyle:{"margin-bottom":"10px"},attrs:{"color":"green","block":"","small":"","dark":""},on:{"click":function($event){return _vm.handleRefresh('totem')}}},[_vm._v(" refresh ")]),_c('preview',{attrs:{"url":_vm.prepareTotem(_vm.LMUTotem),"locale":_vm.locale,"size":{
        w: 1080,
        h: 1920,
      },"className":"dtouchTotemPreview","name":"if-totem"}})],1),_c('v-flex',{staticClass:"dtouchDesktopPreview",staticStyle:{"padding":"5px"},attrs:{"xs7":""}},[_c('v-btn',{staticStyle:{"margin-bottom":"10px"},attrs:{"color":"green","block":"","small":"","dark":""},on:{"click":function($event){return _vm.handleRefresh('desktop')}}},[_vm._v(" refresh ")]),_c('preview',{attrs:{"url":_vm.prepareDesktop(_vm.LMUDesktop),"locale":_vm.locale,"size":{
        w: 1920,
        h: 1080,
      },"className":"dtouchDesktopPreview","name":"if-desktop"}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }