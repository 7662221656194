<template>
      <v-layout
        wrap
      >
        <v-flex xs2 style="padding: 5px;" class="dtouchMobilePreview">
          <v-btn
            @click="handleRefresh('dtouch')"
            color="green"
            block
            small
            dark
            style="margin-bottom: 10px;"
          >
            refresh
          </v-btn>
          <preview
            :url="prepareDtouch(LMUDtouch)"
            :locale="locale"
            :size="{
              w: 375,
              h: 799,
            }"
            className="dtouchMobilePreview"
            name="if-dtouch"
          />
        </v-flex>
        <v-flex xs3 style="padding: 5px;" class="dtouchTotemPreview">
          <v-btn
            @click="handleRefresh('totem')"
            color="green"
            block
            small
            dark
            style="margin-bottom: 10px;"
          >
            refresh
          </v-btn>
          <preview
            :url="prepareTotem(LMUTotem)"
            :locale="locale"
            :size="{
              w: 1080,
              h: 1920,
            }"
            className="dtouchTotemPreview"
            name="if-totem"
          />
        </v-flex>
        <v-flex xs7 style="padding: 5px;" class="dtouchDesktopPreview">
          <v-btn
            @click="handleRefresh('desktop')"
            color="green"
            block
            small
            dark
            style="margin-bottom: 10px;"
          >
            refresh
          </v-btn>
          <preview
            :url="prepareDesktop(LMUDesktop)"
            :locale="locale"
            :size="{
              w: 1920,
              h: 1080,
            }"
            className="dtouchDesktopPreview"
            name="if-desktop"
          />
        </v-flex>
      </v-layout>
</template>
<script>
//import api from '@/services/api'
import Preview from './Preview'
export default {
  components: {
    Preview,
  },
  props: {
    locale: {
      type: String,
      default: 'es'
    },
  },
  data: () => ({
    loading: true, 
    dialog: false,
    LMUDtouch: null,
    LMUTotem: null,
    LMUDesktop: null,
  }),
  computed: {
    domain () {
      return this.$route.params.id
    },
    port () {
      return '8080'
    },
    h () {
      return process.env.NODE_ENV === 'development' ? `http` : 'https'
    },
    b () {
      return process.env.NODE_ENV === 'development' ? `local:${this.port}` : 'es'
    },
  },
  mounted () {
  },
  methods: {
    handleRefresh (type) {
      for (const i of document.getElementsByName(`if-${type}`)){
        i.src = ''
        setTimeout(() => {
          i.src = type === 'dtouch' ? this.prepareDtouch() : type === 'totem' ? this.prepareTotem() : this.prepareDesktop()
        }, 1000)
      }
    },
    prepareDtouch () {
      const lmu = new Date().toISOString()
      return `${this.h}://${this.domain}.dtouch.${this.b}/#/?fm=true&lmu=${lmu}`
    },
    prepareTotem () {
      const lmu = new Date().toISOString()
      return `${this.h}://${this.domain}.dtouch.${this.b}/#/totem?fullscreen=true&lmu=${lmu}`
    },
    prepareDesktop () {
      const lmu = new Date().toISOString()
      return `${this.h}://${this.domain}.dtouch.${this.b}/#/?lmu=${lmu}`
    },
    handleOpenExternal () {
      const url = `${window.location.origin}/#/dtouch-preview/${this.domain}`
      alert(url)
      window.open(url)
      //window.open("https://www.w3schools.com")
    },
  },
}
</script>
